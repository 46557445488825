<template>
	<div class="newsList_box">
		<div class="newsList">
			<div class="publicTitle">{{PublicJs.Decrypt($route.query.col_name)}}</div>
			<div v-if="total">
				<div class="newsItem" v-for="(item,index) in nesList" :key="index">
					<img :src="item.inf_thumb" class="newsImg" v-if="item.inf_thumb" />
					<img src="@/views/images/newsImg.png" class="newsImg" v-else />
					<div class="newsCon">
						<div class="newsDate">
							<p class="newsMonth">{{item.createtime.substring(0, 7)}}</p>
							<p class="newsDay">{{item.createtime.substring(8, 10)}}</p>
						</div>
						<div class="newsContent">
							<div class="newsTit" @click="gotoDetails(item)">{{item.inf_title}}</div>
							<div class="newsDes">{{item.inf_summary}}</div>
						</div>
					</div>
				</div>
				<el-pagination
					v-if="total > pageSize"
				  background
				  layout="prev, pager, next"
				  :total="total"
				  :page-size="pageSize"
				  @current-change="changPage">
				</el-pagination>
			</div>
			<div v-else class="noData">
				<img src="@/views/images/noData.png" />
				<p>暂无数据</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				nesList:[],//新闻列表
				total:0,
				pageSize:10,
			}
		},
		watch: {
			'$route'(to, from) {
				if (to.query) {
					//获取新闻列表
					this.getNewsList(1)
				}
			}
		},
		methods: {
			//获取新闻列表
			getNewsList(page){
				this.$http.post(this.PublicJs.publicPath + "/api/website.index/information.html", {
					are_code:this.$cookies.get("are_code"),
					inf_type:this.PublicJs.Decrypt(this.$route.query.col_id),
					page:page,
					limit:this.pageSize,
					inf_istop:""
				}, {
					emulateJSON: true,
				}).then(response => {
					if(response.data.code == 1){
						this.total = response.data.data.total;
						this.nesList = response.data.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			changPage(val){
				//获取新闻列表
				this.getNewsList(val)
			},
			//跳转详情
			gotoDetails(item){
				this.$router.push({
					path: "/newsDetails",
					query: {
						col_name:this.$route.query.col_name,
						ids:this.PublicJs.Encrypt(item.inf_id)
					}
				});
			}
		},
		created() {
			//获取新闻列表
			this.getNewsList(1)
		},
	}
</script>

<style scoped="scoped">
	.newsList_box{
		width: 1200px;
		margin: 0 auto;
		padding-top: 34px;
		padding-bottom: 52px;
	}
	.newsList{
		width: 100%;
		background-color: #FFF;
		padding: 0 29px 32px 29px;
		box-shadow: 0px 3px 7px 0px rgba(51,51,51,0.09); 
	}
	.newsItem{
		height: 100px;
		width: 100%;
		position: relative;
		padding-left: 189px;
		margin-bottom: 31px;
	}
	.newsImg{
		position: absolute;
		top: 0;
		left: 0;
		width: 160px;
		height: 90px;
	}
	.newsCon{
		width: 100%;
		height: 100%;
		border-bottom: 1px solid #F4F4F4;
		position: relative;
		padding-left: 104px;
	}
	.newsDate{
		position: absolute;
		left: 0;
		top: 0;
		color: #999999;
		text-align: center;
	}
	.newsMonth{
		font-size: 14px;
	}
	.newsDay{
		font-size: 30px;
		margin-top: 10px;
	}
	.newsContent{
		font-size: 14px;
		color: #333;
	}
	.newsTit{
		font-size: 16px;
		font-weight: bold;
		overflow:hidden;
		text-overflow:ellipsis; 
		white-space:nowrap; 
		cursor: pointer;
		height: 21px;
	}
	.newsTit:hover{
		color: #4d6ef2;
	}
	.newsDes{
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		margin-top: 9px;
		line-height: 24px;
		height: 48px;
	}
	.el-pagination{
		margin-top: 20px;
		text-align: center;
	}
	.noData{
		text-align: center;
	}
	.noData p{
		padding: 10px 0;
		font-size: 14px;
	}
</style>
